import React, { Component } from "react";
import Counter, { CounterType } from "./counter";

interface CountersProps {
  onReset: () => void;
  onIncrement: (counter: CounterType) => void;
  onDelete: (id: number) => void;
  onDecrement: (counter: CounterType) => void;
  counters: Array<CounterType>;
  onRestart: () => void;
}

class Counters extends Component<CountersProps, never> {
  render(): JSX.Element {
    const {
      onReset,
      onIncrement,
      onDelete,
      onDecrement,
      counters,
      onRestart
    } = this.props;
    return (
      <div>
        <button
          className="btn btn-success m-2"
          onClick={onReset}
          disabled={counters.length === 0}
        >
          <i className="fa fa-sync" aria-hidden="true" />
        </button>
        <button
          className="btn btn-primary m-2"
          onClick={onRestart}
          disabled={counters.length !== 0}
        >
          <i className="fa fa-recycle" aria-hidden="true" />
        </button>
        {counters.map((counter: CounterType) => (
          <Counter
            key={counter.id}
            counter={counter}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            onDelete={onDelete}
          />
        ))}
      </div>
    );
  }
}

export default Counters;
