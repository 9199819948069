import B2 from 'backblaze-b2';

const b2 = new B2({
  applicationKeyId: '229fb6aeef96',
  applicationKey: '00151f31d4584483c547fbdb9e1b358ca81fbad773'
});

export async function GetBucket(): Promise<void> {
  try {
    await b2.authorize(); // must authorize first
    const response = await b2.getBucket({ bucketName: 'Reacto' });
    console.log(response.data);
  } catch (err) {
    console.log('Error getting bucket:', err);
  }
}
